import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Masonry from "react-masonry-css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorMessage, successMessage } from "./../helpers/Message";
import instance from "./../services/apiServices/Api";
import Storage from "./../services/local_storage/localStorage";
import NoDataFound from "./../components/NoDataFound";
import SEO from "./SEO";
import {
    Box,
    Card,
    CardMedia,
    Container,
    Grid,
    TextField,
    Typography,
    IconButton,
    CircularProgress,
    Modal,
    Fade,
    Backdrop,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import CustomButton from "./CustomButton";
import { AppColors } from "../constants/AppColors";
import { AppTexts } from "../constants/AppTexts";
import AppCustomButton, { buttonSizes, buttonVariants } from "./AppCustomButton";

const WishlistDetailsComponent = ({ id }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const token = Storage.getToken("token");
    const header = { headers: { Authorization: `Bearer ${token}` } };
    const [details, setDetails] = useState([]);
    const [creatorDetail, setCreatorDetail] = useState({});
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isButtonLoading, setButtonLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 2,
    };

    useEffect(() => {
        getData(1);
    }, []);

    const getData = (page) => {
        const data = {
            id: id,
            per_page: 15,
            current_page: page,
            order_by: "newness_desc",
        };
        instance
            .post("/wishlists/detail", data, header)
            .then((res) => {
                setLoading(false);
                setCreatorDetail(res.data.data.wishlist);
                setDetails((preData) => {
                    return [...new Set([...preData, ...res.data.data.items])];
                });
                setPage(res.data.data.nextPageNo);
                setHasMore(res.data.data.hasMorePages);
            })
            .catch((err) => {
                setLoading(false);
                errorMessage("Error", err.response.data.message);
            });
    };

    const deleteDownload = (download_id) => {
        const data = {
            wishlist_id: id,
            download_id: download_id,
        };
        instance
            .post("/wishlists/download/delete", data, header)
            .then((res) => {
                if (res.status === 200) {
                    successMessage("Success", "Deleted successfully").then(() => {
                        setDetails([]);
                        getData(1);
                    });
                }
            })
            .catch((err) => errorMessage("Error", err.message));
    };

    const formik = useFormik({
        initialValues: {
            to_email: "",
            description: "",
            wishlist_id: id,
        },
        validationSchema: Yup.object({
            to_email: Yup.string().required("Email is required").email("Invalid Email"),
            description: Yup.string().required("Description is required"),
        }),

        onSubmit: (values) => {
            setButtonLoading(true);
            instance
                .post("/wishlists/share", values, header)
                .then((res) => {
                    setButtonLoading(false);
                    if (res.status === 200) {
                        formik.resetForm();
                        successMessage("Success!", "Wishlist Invitation sent successfully");
                        handleCloseModal();
                    } else {
                        errorMessage("Error!", res.message);
                    }
                })
                .catch((error) => {
                    setButtonLoading(false);
                    errorMessage("Error!", error.response.data.message);
                });
        },
    });

    const wishlistItems =
        details.length > 0 &&
        details?.map((item, i) => (
            <Card
                key={i}
                sx={{
                    position: 'relative',
                    borderRadius: 2,
                    overflow: 'hidden',
                    mb: 4,
                    '&:hover .overlay': {
                        opacity: 1,
                    },
                }}
            >
                <CardMedia
                    component="img"
                    image={item?.download?.watermarked_file.file}
                    alt={item?.download?.title}
                    sx={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        transition: 'transform 0.2s',
                        '&:hover': {
                            transform: 'scale(1.05)',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => navigate(`/downloads/${item.download.slug}`)}
                />
                <Box
                    className="overlay"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        opacity: 0,
                        transition: 'opacity 0.3s',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                        textAlign: 'center',
                        p: 2,
                    }}
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: '#fff',
                        }}
                        onClick={() => deleteDownload(item.download.id)}
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                        {item?.download?.title}
                    </Typography>
                </Box>
            </Card>
        ));

    return (
        <>
            <SEO />
            <Container>
                <Box sx={{ py: 4, position: "relative" }}>
                    <Typography variant="h4" gutterBottom>
                        {creatorDetail.name}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <b>Created By - </b>
                        <Link to={`/contributor-login/${creatorDetail?.user?.username}`}>
                            {creatorDetail?.user?.first_name} {creatorDetail?.user?.last_name}
                        </Link>
                    </Typography>

                    <Box sx={{ position: "absolute", top: 16, right: 16 }}>
                        <CustomButton
                            backgroundColor={AppColors.PrimaryColor}
                            borderRadius={2}
                            iconColor={AppColors.WhiteColor}
                            borderColor="none"
                            size="md"
                            hoverBackgroundColor={AppColors.PrimaryHoverColor}
                            onClick={handleOpenModal}
                        >
                            <Typography variant="body1" align="center" gutterBottom>
                                {AppTexts.Share}
                            </Typography>
                        </CustomButton>
                    </Box>
                  
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                {loading && (
                                    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                        <CircularProgress />
                                    </Box>
                                )}
                                <InfiniteScroll
                                    pageStart={1}
                                    loadMore={() => getData(page)}
                                    hasMore={hasMore}
                                    loader={
                                        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                            <CircularProgress />
                                        </Box>
                                    }
                                >
                                    {details.length > 0 ? (
                                        <Masonry
                                            breakpointCols={breakpointColumnsObj}
                                            className="my-masonry-grid"
                                            columnClassName="my-masonry-grid_column"
                                        >
                                            {wishlistItems}
                                        </Masonry>
                                    ) : (
                                        !loading && <NoDataFound title={"No record Found"} />
                                    )}
                                </InfiniteScroll>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableBackdropClick
            >
                <Fade in={openModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 600,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                        }}
                    >
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: '#000',
                            }}
                            onClick={handleCloseModal}
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>
                            Share Wishlist
                        </Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Friend's Email address"
                                name="to_email"
                                type="email"
                                variant="outlined"
                                {...formik.getFieldProps("to_email")}
                                error={formik.touched.to_email && Boolean(formik.errors.to_email)}
                                helperText={formik.touched.to_email && formik.errors.to_email}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Your Message"
                                name="description"
                                multiline
                                rows={4}
                                variant="outlined"
                                {...formik.getFieldProps("description")}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                {formik.values.to_email && formik.values.description ? (
                                    <AppCustomButton
                                        size={buttonSizes.LARGE}
                                        variant={buttonVariants.PRIMARY}
                                        type="submit"
                                        disabled={isButtonLoading}
                                    >
                                        {isButtonLoading ? (
                                            <CircularProgress size={24} color='inherit' />
                                        ) : (
                                            AppTexts.Share
                                        )}
                                    </AppCustomButton>
                                ) : (
                                    <AppCustomButton
                                        size={buttonSizes.LARGE}
                                        variant={buttonVariants.SECONDARY}
                                        disabled
                                    >
                                       {AppTexts.Share}
                                    </AppCustomButton>
                                )}
                            </Box>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default WishlistDetailsComponent;
