import axios from "axios"
import Storage from "../local_storage/localStorage";
import { errorMessage } from '../../helpers/Message'
const langs = ["fr","pt"];
let subdomain = '';
let urlLocation  = window.location.host.split('.');
if(urlLocation?.length && langs.indexOf(urlLocation[0])> -1){
      subdomain =  `${langs[langs.indexOf(urlLocation[0])]}.`;
}

const instance = axios.create({
  baseURL:  `https://${subdomain}${process.env.REACT_APP_BASE_URL}`
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  let token = Storage.getToken();

  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

  return response;
}, function (error) {
  if (error.response.status === 401) {
    console.log("Error ", error);
    errorMessage('Error', error.response.data.message).then(() => {
      Storage.removeData("token");
      Storage.removeData("userData");
      window.location = '/login';
    })
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default instance;