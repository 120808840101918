import React, { useEffect, useState, useCallback } from "react";
import instance from "../services/apiServices/Api";
import { errorMessage } from "../helpers/Message";
import Storage from "../services/local_storage/localStorage";
import { Modal, Card, CardContent, Typography, Box, Grid, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BadgeCard = ({ isShowcasePage = false, username = "" }) => {
  const [userBadge, setUserBadge] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [error, setError] = useState(null);
  const token = Storage.getToken("token");

  const formatBadges = (badges) =>
    badges.map((badge) => ({
      url: badge.file,
      name: badge.name,
    }));

  const getBadgeData = useCallback(() => {
    setLoading(true);
    setError(null);

    const endpoint = isShowcasePage && username ? "/contributor-badges-info" : "/contributor/badges";
    const payload = isShowcasePage && username ? { username } : undefined;
    const headers = !isShowcasePage ? { Authorization: `Bearer ${token}` } : undefined;

    const request = isShowcasePage && username
      ? instance.post(endpoint, payload, { headers })
      : instance.get(endpoint, { headers });

    request
      .then((res) => {
        if (res.data.data.length > 0) {
          setUserBadge(formatBadges(res.data.data));
        } else {
          setUserBadge([]);
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.error("Network error:", error);
          return;
        }
        const errorMessage = error.response?.data?.message || "An error occurred";
        setError(errorMessage);
      })
      .finally(() => setLoading(false));
  }, [token, isShowcasePage, username]);

  useEffect(() => {
    getBadgeData();
  }, [getBadgeData]);

  const handleBadgeClick = (badge) => {
    setSelectedBadge(badge);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedBadge(null);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
          <CircularProgress />
        </Box>
      ) : userBadge.length > 0 ? (
        <Grid container spacing={2}>
          {userBadge.map((badge, index) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              key={index}
              onClick={() => handleBadgeClick(badge)}
              sx={{ padding: "10px" }}
            >
              <Box
                textAlign="center"
                sx={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  transition: "0.3s",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                  "&:hover": { boxShadow: "0px 0px 8px rgba(0,0,0,0.1)" },
                }}
              >
                <Box
                  component="img"
                  src={badge.url}
                  alt={`Badge ${index}`}
                  sx={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
                <Typography sx={{ padding: "10px" }} variant="body2">
                  {badge.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No Badge received.</Typography>
      )}

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={modalStyle} elevation={4}>
          {selectedBadge && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 1,
                }}
              >
                <CloseIcon onClick={handleCloseModal} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 3,
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <img
                  src={selectedBadge.url}
                  alt={`Badge ${selectedBadge.name}`}
                  style={{
                    width: 130,
                    height: 130,
                    objectFit: "cover",
                    borderRadius: "50%",
                    border: "3px solid #c3e711",
                  }}
                />
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {selectedBadge.name}
                </Typography>
              </CardContent>
            </>
          )}
        </Card>
      </Modal>
    </>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "1px solid #eee",
  boxShadow: 24,
  p: 4,
  display: "block",
  height: "auto",
  maxHeight: "calc(100% - 96px)",
  overflowY: "auto",
};

export default BadgeCard;
