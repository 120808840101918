import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import instance from "../../services/apiServices/Api";
import { errorMessage, successMessage } from "../../helpers/Message";
import Storage from "../../services/local_storage/localStorage";
import SimpleBackdrop from "../../components/Backdrop";
import userLogo from "../../assets/user-logo.png";
import banner from "../../assets/defaultBanner.jpg";
import {
  faImages,
  faInfoCircle,
  faShareNodes,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Card,
  CardMedia,
  CircularProgress,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/CloudUpload";
import TextInput from "./../../components/TextInput";
import CountrySelect from "./../../components/CountrySelect";
import AppCustomButton, {
  buttonSizes,
  buttonVariants,
} from "./../../components/AppCustomButton";
import BadgeCard from "../../components/BadgeCard";
import { useNavigate } from "react-router-dom";
import { AppTexts } from "../../constants/AppTexts";
import CustomButton from "../../components/CustomButton";
import { AppColors } from "../../constants/AppColors";

const ProfileUpdate = () => {
  const token = Storage.getToken("token");
  const navigate = useNavigate();
  const [file, setFile] = useState({ avatar: "", cover_photo_url: "" });
  const [preview, setPreview] = useState({ avatar: "", cover_photo_url: "" });
  const [userRole, setUserRole] = useState({});
  const [userName, setUserName] = useState("");
  const [address, setAddress] = useState({});
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [active, setActive] = useState("userInfo");

  const urlValidations = Yup.string()
    .matches(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?.*$/,
      "Enter correct url!"
    )
    .nullable();

  useEffect(() => {
    getData();
    allCountry();
  }, []);

  const getData = () => {
    setLoading(true);
    instance
      .get("/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setPreview({
          avatar: res.data.data.avatar,
          cover_photo_url: res.data.data.cover_photo_url,
        });
        setAddress(res.data.data.address);
        setUserRole(res.data.data.role);
        setUserName(res.data.data.username);
        Storage.SetPreferenceStep(res?.data?.data?.current_step);
        Storage.SetUserData({
          id: res.data.data.id,
          first_name: res.data.data.first_name,
          last_name: res.data.data.last_name,
          email: res.data.data.email,
          username: res.data.data.username,
          role_id: res.data.data.role_id,
          role_label: res.data.data.role.label,
          last_login: res.data.data.last_login,
          avatar: res.data.data.avatar,
        });

        for (let item in formikUserInfo.initialValues) {
          if (["avatar", "cover_photo_url"].includes(item)) continue;
          if (["country"].includes(item)) {
            formikUserInfo.setFieldValue(
              item,
              res?.data?.data?.address?.country
            );
          }
          if (
            [
              "facebook_url",
              "youtube_url",
              "instagram_url",
              "twitter_url",
            ].includes(item)
          ) {
            res?.data?.data?.user_social_links.map((item) =>
              formikUserInfo.setFieldValue(`${item.type}_url`, item.url)
            );
          }
          if ([item] in res.data.data) {
            formikUserInfo.setFieldValue(item, res.data.data[item]);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        errorMessage("Error", error.response.data.message);
      });
  };

  const multiSearch = (name, newInputValue) => {
    formikUserInfo.setFieldValue(name, newInputValue);
  };

  const fileChange = (e) => {
    setFile({ ...file, [e.target.name]: e.target.files[0] });
    setPreview({
      ...preview,
      [e.target.name]: URL.createObjectURL(e.target.files[0]),
    });
  };

  const formikUserInfo = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      contact_email: "",
      paypal_email: "",
      store_name: "",
      is_public: 0,
      url: "",
      display_name: "",
      country: "",
      username: "",
      facebook_url: "",
      youtube_url: "",
      instagram_url: "",
      twitter_url: "",
      description: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is Required").nullable(),
      last_name: Yup.string().required("Last Name is Required").nullable(),
      username: Yup.string()
        .min(3, "3 minimum characters required")
        .max(30, "30 maximum characters required")
        .matches(
          /^[a-zA-Z0-9]([a-zA-Z0-9]|[_-]{0,1}[a-zA-Z0-9]){2,28}[a-zA-Z0-9]$/,
          "Username must be 3-30 characters and No spaces or special characters."
        )
        .test(
          "username",
          "This username has already been taken",
          function (username) {
            return checkAvailabilityUsername(username);
          }
        )
        .required("You must enter a username"),
      contact_email: Yup.string().email("Invalid Email").nullable(),
      paypal_email: Yup.string().email("Invalid Email").nullable(),
      url: urlValidations,
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      if (file.avatar) {
        formData.append("avatar", file.avatar);
      }
      if (file.cover_photo_url) {
        formData.append("cover_photo_url", file.cover_photo_url);
      }

      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("country", values.country);
      formData.append("username", values.username);
      formData.append("is_public", values.is_public);
      formData.append(
        "contact_email",
        values.contact_email === null ? "" : values.contact_email
      );
      formData.append(
        "paypal_email",
        values.paypal_email === null ? "" : values.paypal_email
      );
      formData.append(
        "store_name",
        values.store_name === null ? "" : values.store_name
      );
      formData.append("url", values.url);
      formData.append(
        "display_name",
        values.display_name === null ? "" : values.display_name
      );
      formData.append("user_social_link[0][type]", "facebook");
      formData.append(
        "user_social_link[0][url]",
        values.facebook_url === null ? "" : values.facebook_url
      );
      formData.append("user_social_link[1][type]", "youtube");
      formData.append(
        "user_social_link[1][url]",
        values.youtube_url === null ? "" : values.youtube_url
      );
      formData.append("user_social_link[2][type]", "instagram");
      formData.append(
        "user_social_link[2][url]",
        values.instagram_url === null ? "" : values.instagram_url
      );
      formData.append("user_social_link[3][type]", "twitter");
      formData.append(
        "user_social_link[3][url]",
        values.twitter_url === null ? "" : values.twitter_url
      );
      formData.append(
        "description",
        values.description === null ? "" : values.description
      );

      instance
        .post("/user", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            formikUserInfo.resetForm();
            successMessage(
              "Success!",
              "Congratulations, your profile has been successfully updated."
            );
            getData();
          } else {
            let message = () => {
              for (let i in res.errors) {
                return `${res.errors[i]}`;
              }
            };
            errorMessage("Error!", message ?? "Something went wrong");
          }
        })
        .catch((error) => {
          setLoading(false);
          let message = Object?.values(error.response.data.errors);
          errorMessage("Error!", message.join("\r\n"));
        });
    },
  });

  const checkAvailabilityUsername = async (username) => {
    const data = { type: "username", value: username };
    if (username === userName) {
      return true;
    } else {
      let exists = false;
      if (username) {
        await instance
          .post("/auth/checkEmailUsernameExist", data)
          .then((res) => {
            exists = res.data.data.exist === true ? false : true;
          });
      }
      return exists;
    }
  };

  const allCountry = () => {
    instance
      .get(`/countries`)
      .then((res) => {
        setCountry(res.data.data);
      })
      .catch((err) => {
        errorMessage("Error", err.response.data.message);
      });
  };

  const handleChange = (event, newValue) => {
    setActive(newValue);
  };

  const RemoveImage = (type) => {
    instance
      .post("/user/remove-profile-photos", { type: type })
      .then((res) => {
        successMessage("Success!", res.data.message).then(() => getData());
      })
      .catch((error) => {
        setLoading(false);
        errorMessage("Error", error.response.data.message);
      });
  };

  const handleFileClick = (type) => {
    document.getElementById(type).click();
  };

  return (
    <>
      <Container>
        <Box sx={{ py: 4 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography variant="h4">Profile</Typography>
            {userRole?.role !== "user" && (
              <CustomButton
                backgroundColor={AppColors.PrimaryColor}
                borderRadius={10}
                iconColor={AppColors.WhiteColor}
                borderColor="none"
                size="xlg"
                hoverBackgroundColor={AppColors.PrimaryHoverColor}
                onClick={() => navigate(`/contributor-login/${userName}`)}
                disabled={loading}
              >
                <Typography
                  variant="body1"
                  align="center"
                  gutterBottom
                  sx={{ color: AppColors.WhiteColor }}
                >
                  {AppTexts.ViewMyPortfolio}
                </Typography>
              </CustomButton>
            )}
          </Box>

          <Tabs
            value={active}
            onChange={handleChange}
            aria-label="profile tabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FF5BEF",
                height: "3px",
              },
            }}
          >
            <Tab
              label={
                <Box sx={{ display: "flex", marginRight: "10px", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ marginRight: "8px", fontSize: "1rem" }} 
                  />
                  <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                    User Information
                  </Typography>
                </Box>
              }
              value="userInfo"
              sx={{
                textTransform: "none",
                borderRadius: "20px",
                backgroundColor:
                  active === "userInfo" ? "#f5f5f5" : "transparent",
                margin: "0 8px",
              }}
            />
            <Tab
              label={
                <Box sx={{ display: "flex", marginRight: "10px", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faIdBadge}
                    style={{ marginRight: "8px", fontSize: "1rem" }} 
                  />
                  <Typography variant="h6" sx={{ fontSize: "1rem" }}> 
                    Badges
                  </Typography>
                </Box>
              }
              value="badges"
              sx={{
                textTransform: "none",
                borderRadius: "20px",
                backgroundColor:
                  active === "badges" ? "#f5f5f5" : "transparent",
                margin: "0 8px",
              }}
            />
            <Tab
              label={
                <Box sx={{ display: "flex", marginRight: "10px", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faImages}
                    style={{ marginRight: "8px", fontSize: "1rem" }} 
                  />
                  <Typography variant="h6" sx={{ fontSize: "1rem" }}> 
                    Images
                  </Typography>
                </Box>
              }
              value="images"
              sx={{
                textTransform: "none",
                borderRadius: "20px",
                backgroundColor:
                  active === "images" ? "#f5f5f5" : "transparent",
                margin: "0 8px",
              }}
            />
            <Tab
              label={
                <Box sx={{ display: "flex", marginRight: "10px", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{ marginRight: "8px", fontSize: "1rem" }} 
                  />
                  <Typography variant="h6" sx={{ fontSize: "1rem" }}> 
                    Recognition
                  </Typography>
                </Box>
              }
              value="contributorRecognition"
              sx={{
                textTransform: "none",
                borderRadius: "20px",
                backgroundColor:
                  active === "contributorRecognition"
                    ? "#f5f5f5"
                    : "transparent",
                margin: "0 8px",
              }}
              hidden={userRole?.role === "user"}
            />
          </Tabs>

        </Box>

        <form onSubmit={formikUserInfo.handleSubmit}>
          {active === "userInfo" && (
            <Box mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    label="First Name"
                    name="first_name"
                    value={formikUserInfo.values.first_name}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.first_name &&
                      Boolean(formikUserInfo.errors.first_name)
                    }
                    helperText={
                      formikUserInfo.touched.first_name &&
                      formikUserInfo.errors.first_name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    label="Last Name"
                    name="last_name"
                    value={formikUserInfo.values.last_name}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.last_name &&
                      Boolean(formikUserInfo.errors.last_name)
                    }
                    helperText={
                      formikUserInfo.touched.last_name &&
                      formikUserInfo.errors.last_name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    label="Contact Email"
                    name="contact_email"
                    value={formikUserInfo.values.contact_email}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.contact_email &&
                      Boolean(formikUserInfo.errors.contact_email)
                    }
                    helperText={
                      formikUserInfo.touched.contact_email &&
                      formikUserInfo.errors.contact_email
                    }
                  />
                </Grid>
                {userRole?.role !== "user" && (
                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      label="PayPal Email"
                      name="paypal_email"
                      value={formikUserInfo.values.paypal_email}
                      onChange={formikUserInfo.handleChange}
                      onBlur={formikUserInfo.handleBlur}
                      error={
                        formikUserInfo.touched.paypal_email &&
                        Boolean(formikUserInfo.errors.paypal_email)
                      }
                      helperText={
                        formikUserInfo.touched.paypal_email &&
                        formikUserInfo.errors.paypal_email
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    label="Display Name"
                    name="display_name"
                    value={formikUserInfo.values.display_name}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.display_name &&
                      Boolean(formikUserInfo.errors.display_name)
                    }
                    helperText={
                      formikUserInfo.touched.display_name &&
                      formikUserInfo.errors.display_name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CountrySelect
                    options={country}
                    label="Country"
                    name="country"
                    value={formikUserInfo.values.country}
                    onChange={(name, value) =>
                      formikUserInfo.setFieldValue(name, value)
                    }
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.country &&
                      Boolean(formikUserInfo.errors.country)
                    }
                    helperText={
                      formikUserInfo.touched.country &&
                      formikUserInfo.errors.country
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    label="User Name"
                    name="username"
                    value={formikUserInfo.values.username}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.username &&
                      Boolean(formikUserInfo.errors.username)
                    }
                    helperText={
                      formikUserInfo.touched.username &&
                      formikUserInfo.errors.username
                    }
                  />
                </Grid>
                {userRole?.role !== "user" && (
                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      label="Store Name"
                      name="store_name"
                      value={formikUserInfo.values.store_name}
                      onChange={formikUserInfo.handleChange}
                      onBlur={formikUserInfo.handleBlur}
                      error={
                        formikUserInfo.touched.store_name &&
                        Boolean(formikUserInfo.errors.store_name)
                      }
                      helperText={
                        formikUserInfo.touched.store_name &&
                        formikUserInfo.errors.store_name
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    label="User URL"
                    name="url"
                    value={formikUserInfo.values.url}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.url &&
                      Boolean(formikUserInfo.errors.url)
                    }
                    helperText={
                      formikUserInfo.touched.url && formikUserInfo.errors.url
                    }
                  />
                </Grid>
                {userRole?.role !== "user" && (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Public Profile Preferences
                    </Typography>
                    <Typography variant="body2">
                      http://pichastock.com/contributor-login/
                      <span className="updateUsername">
                        {formikUserInfo.values.username}
                      </span>
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formikUserInfo.values.is_public === 1}
                          onChange={(e) =>
                            formikUserInfo.setFieldValue(
                              "is_public",
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                      }
                      label="Public visibility of your profile"
                    />
                  </Grid>
                )}
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
                <CustomButton
                  backgroundColor={AppColors.PrimaryColor}
                  borderRadius={10}
                  iconColor={AppColors.WhiteColor}
                  borderColor="none"
                  size="xlg"
                  hoverBackgroundColor={AppColors.PrimaryHoverColor}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Typography
                      variant="body1"
                      align="center"
                      gutterBottom
                      sx={{ color: AppColors.WhiteColor }}
                    >
                      {AppTexts.Update}
                    </Typography>
                  )}
                </CustomButton>
              </Box>
            </Box>
          )}

          {active === "contributorRecognition" && (
            <Box mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextInput
                    label="Facebook URL"
                    name="facebook_url"
                    value={formikUserInfo.values.facebook_url}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.facebook_url &&
                      Boolean(formikUserInfo.errors.facebook_url)
                    }
                    helperText={
                      formikUserInfo.touched.facebook_url &&
                      formikUserInfo.errors.facebook_url
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextInput
                    label="YouTube URL"
                    name="youtube_url"
                    value={formikUserInfo.values.youtube_url}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.youtube_url &&
                      Boolean(formikUserInfo.errors.youtube_url)
                    }
                    helperText={
                      formikUserInfo.touched.youtube_url &&
                      formikUserInfo.errors.youtube_url
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextInput
                    label="Instagram URL"
                    name="instagram_url"
                    value={formikUserInfo.values.instagram_url}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.instagram_url &&
                      Boolean(formikUserInfo.errors.instagram_url)
                    }
                    helperText={
                      formikUserInfo.touched.instagram_url &&
                      formikUserInfo.errors.instagram_url
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextInput
                    label="Twitter URL"
                    name="twitter_url"
                    value={formikUserInfo.values.twitter_url}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.twitter_url &&
                      Boolean(formikUserInfo.errors.twitter_url)
                    }
                    helperText={
                      formikUserInfo.touched.twitter_url &&
                      formikUserInfo.errors.twitter_url
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="User Bio"
                    name="description"
                    multiline
                    rows={5}
                    value={formikUserInfo.values.description}
                    onChange={formikUserInfo.handleChange}
                    onBlur={formikUserInfo.handleBlur}
                    error={
                      formikUserInfo.touched.description &&
                      Boolean(formikUserInfo.errors.description)
                    }
                    helperText={
                      formikUserInfo.touched.description &&
                      formikUserInfo.errors.description
                    }
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
                <CustomButton
                  backgroundColor={AppColors.PrimaryColor}
                  borderRadius={10}
                  iconColor={AppColors.WhiteColor}
                  borderColor="none"
                  size="xlg"
                  hoverBackgroundColor={AppColors.PrimaryHoverColor}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Typography
                      variant="body1"
                      align="center"
                      gutterBottom
                      sx={{ color: AppColors.WhiteColor }}
                    >
                      {AppTexts.Update}
                    </Typography>
                  )}
                </CustomButton>
              </Box>
            </Box>
          )}

          {active === "images" && (
            <Box mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Avatar Image
                  </Typography>
                  <Card sx={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      image={preview.avatar || userLogo}
                      alt="Avatar"
                      sx={{ height: 200 }}
                    />
                    {preview.avatar && preview.avatar !== userLogo && (
                      <IconButton
                        sx={{ position: "absolute", top: 10, right: 10 }}
                        onClick={() => RemoveImage("avatar")}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Card>
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="file"
                      id="avatar"
                      name="avatar"
                      style={{ display: "none" }}
                      onChange={fileChange}
                    />

                    <CustomButton
                      backgroundColor={'transparent'}
                      borderRadius={10}
                      icon={<UploadIcon sx={{ mt: -1}} />}
                      iconColor={AppColors.BlackColor}
                      borderColor={AppColors.BlackColor}
                      size="md"
                      hoverBackgroundColor={'transparent'}
                      onClick={() => handleFileClick("avatar")}
                    >
                      <Typography
                        variant="body1"
                        align="center"
                        gutterBottom
                        sx={{ color: AppColors.BlackColor }}
                      >
                        {AppTexts.UploadAvatar}
                      </Typography>
                    </CustomButton>
                    
                  </Box>
                </Grid>
                {userRole?.role !== "user" && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Cover Image
                    </Typography>
                    <Card sx={{ position: "relative" }}>
                      <CardMedia
                        component="img"
                        image={preview.cover_photo_url || banner}
                        alt="Cover"
                        sx={{ height: 200 }}
                      />
                      {preview.cover_photo_url &&
                        preview.cover_photo_url !== banner && (
                          <IconButton
                            sx={{ position: "absolute", top: 10, right: 10 }}
                            onClick={() => RemoveImage("cover_photo_url")}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                    </Card>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="file"
                        id="cover_photo_url"
                        name="cover_photo_url"
                        style={{ display: "none" }}
                        onChange={fileChange}
                      />

                      <CustomButton
                        backgroundColor={'transparent'}
                        borderRadius={10}
                        icon={<UploadIcon sx={{ mt: -1 }} />}
                        iconColor={AppColors.BlackColor}
                        borderColor={AppColors.BlackColor}
                        size="md"
                        hoverBackgroundColor={'transparent'}
                        onClick={() => handleFileClick("cover_photo_url")}
                      >
                        <Typography
                          variant="body1"
                          align="center"
                          gutterBottom
                          sx={{ color: AppColors.BlackColor }}
                        >
                          {AppTexts.UploadCover}
                        </Typography>
                      </CustomButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
                <CustomButton
                  backgroundColor={AppColors.PrimaryColor}
                  borderRadius={10}
                  iconColor={AppColors.WhiteColor}
                  borderColor="none"
                  size="xlg"
                  hoverBackgroundColor={AppColors.PrimaryHoverColor}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Typography
                      variant="body1"
                      align="center"
                      gutterBottom
                      sx={{ color: AppColors.WhiteColor }}
                    >
                      {AppTexts.Update}
                    </Typography>
                  )}
                </CustomButton>
              </Box>
            </Box>
          )}

          {active === "badges" && (
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Achievements
              </Typography>
              <BadgeCard />
            </Box>
          )}
        </form>
      </Container>
    </>
  );
};

export default ProfileUpdate;
