import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
  Backdrop,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import instance from "../services/apiServices/Api";
import { errorMessage, successMessage } from "../helpers/Message";
import { AppTexts } from "../constants/AppTexts";
import { AddReleaseFormIcon, CloseIcon } from "../constants/AppSVGIcons";
import { AppColors } from "../constants/AppColors";
 
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "80%", md: "70%", lg: "50%" },
  bgcolor: "background.paper",
  border: "1px solid #eee",
  boxShadow: 24,
  p: 4,
  display: "block",
  height: "auto",
  maxHeight: "calc(100% - 96px)",
  overflowY: "auto",
  borderRadius: 5,
};

// Validation schema for the form
const validationSchema = Yup.object({
  name: Yup.string().required("Name is Required"),
  gender: Yup.string().required("Gender is Required").nullable(),
  nationality: Yup.string().required("Nationality is Required").nullable(),
});

// Component for the release form modal
const ReleaseFormModal = ({
  open,
  onClose,
  initialRelease,
  isEdit,
  onRefresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const gender = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
    { name: "Other", value: "other" },
  ];

  useEffect(() => {
    allCountry();
  }, []);

  const formik = useFormik({
    initialValues: initialRelease || {
      name: "",
      gender: "",
      nationality: "",
      file: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();

      // Append file if there's a new file selected
      if (file) {
        formData.append("file", file);
      }

      // Append the rest of the values
      formData.append("name", values.name);
      formData.append("gender", values.gender);
      formData.append("nationality", values.nationality);

      // Determine the endpoint based on whether we're editing or creating a new release
      const endpoint = isEdit
        ? "/contributor/releases/update"
        : "/contributor/releases/create";

      instance
        .post(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            // Include other headers if necessary, like authorization tokens
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            successMessage(
              "Success",
              `Release ${isEdit ? "updated" : "created"} successfully.`
            );
            onClose(); // Close the modal
            onRefresh();
            formik.resetForm(); // Reset the form
            setFileName(""); // Reset the file name
          }
        })
        .catch((error) => {
          setLoading(false);
          errorMessage("Error", error.response.data.message);
        });
    },
  });

  const fileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile?.name);
    formik.setFieldValue("file", selectedFile);
  };

  const allCountry = () => {
    instance
      .get(`/countries`)
      .then((res) => {
        setCountry(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
        errorMessage("Error", err.response?.data?.message);
      });
  };

  return (
    <Backdrop open={open} style={{ zIndex: 1 }}>
      <Modal
        keepMounted
        open={open}
        onClose={() => {}} // This makes the modal static
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              backgroundColor: "white",
            }}
          >
            <CloseIcon width={30} height={30} color={AppColors.BlackColor} />
          </IconButton>
          <Typography id="keep-mounted-modal-title" variant="h4" component="h6">
            {AppTexts.AddNewReleaseForm}
          </Typography>

          <Grid
            sx={{
              height: 25,
            }}
          />

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="name"
                  label="Model Name"
                  placeholder="Model Name"
                  {...formik.getFieldProps("name")}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="gender-select-label">
                    Select Gender
                  </InputLabel>
                  <Select
                    labelId="gender-select-label"
                    id="gender-select"
                    label="Select Gender"
                    name="gender"
                    {...formik.getFieldProps("gender")}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                  >
                    {gender.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.gender && formik.errors.gender}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  fullWidth
                  options={country}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) =>
                    formik.setFieldValue("nationality", value?.code)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Nationality"
                      error={
                        formik.touched.nationality &&
                        Boolean(formik.errors.nationality)
                      }
                      helperText={
                        formik.touched.nationality && formik.errors.nationality
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="file"
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => fileChange(e)}
                  helperText={fileName || "Upload File"}
                  error={formik.touched.file && Boolean(formik.errors.file)}
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    startIcon={loading ? <CircularProgress size={24} color="inherit" /> : null}
                    sx={{
                      borderRadius: 20,
                      textTransform: "none",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: AppColors.PrimaryColor,
                      color: AppColors.WhiteColor,
                      "&:hover": {
                        backgroundColor: AppColors.PrimaryHoverColor, 
                        color: AppColors.WhiteColor, 
                      },
                    }} 
                    variant="contained"
                    size="large"
                    
                  >
                    {!loading && (
                      <AddReleaseFormIcon
                        width={25}
                        height={25}
                        color={AppColors.WhiteColor}
                      />
                    )}
                    &nbsp; {loading ? "Loading..." : AppTexts.Create}
                  </Button>
                </Box>
                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="submit"  startIcon={loading ? <CircularProgress size={24} /> : null} sx={{borderRadius: 3, textTransform: 'none',}}  variant="outlined" >
                  {loading ? 'Loading...' : AppTexts.Create}
                  </Button>
                </Box> */}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </Backdrop>
  );
};

export default ReleaseFormModal;
