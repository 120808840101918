import React from "react";
import CustomFooter from "../../components/CustomFooter";
import SideBar from "../../components/Contributor/SideBar";
import Content from "../../components/Contributor/Content";
import NavbarForDashboard from "../../components/NavbarForDashboard";
import SEO from "../../components/SEO";


function DashboardLayout() {
  return (
    <>
       <SEO />
      <NavbarForDashboard />
      <div className="dashboard-main">
        <div className="container">
          <div className="dashboard-row">
            <SideBar />
            <div className="dashboard-content">
              <Content />
            </div>
          </div>
        </div>
      </div>
      <CustomFooter />
    </>
  );
}

export default DashboardLayout;
