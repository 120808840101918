import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { errorMessage } from "../helpers/Message";
import instance from "../services/apiServices/Api";
import { FollowIcon, FollowingIcon } from "../constants/AppSVGIcons";
import { AppColors } from "../constants/AppColors";

function FollowButton({ item, itemClass }) {
  const isAuth = useSelector((item) => item.isAuth.value);
  const [isFollowed, setIsFollowed] = useState(item.is_followed);
  const navigate = useNavigate();

  const followContributor = (e, id, value) => {
    e.preventDefault();

    if (isAuth) {
      instance
        .post("/follow-user", {
          user_id: id,
          flag: value,
        })
        .then((res) => {
          if (res.data.response === 200) {
            setIsFollowed(value);
            toast.success(res.data.message, {
              autoClose: 1000,
            });
          }
        })
        .catch((error) => {
          console.log("Error:", error);

          errorMessage("Error", "Something Went Wrong");
        });
    } else {
      errorMessage("Error", "Login To Continue").then(() => {
        navigate("/login");
      });
    }
  };
  return (
    <>
      <button
        className={itemClass}
        onClick={(e) => followContributor(e, item.id, !isFollowed)}
      >
        {isFollowed ? (
          <FollowingIcon width={20} height={20} color={AppColors.WhiteColor} />
        ) : (
          <FollowIcon width={20} height={20} color={AppColors.WhiteColor} />
        )}
        {isFollowed ? " Following" : " Follow"}
      </button>
    </>
  );
}

export default FollowButton;
