import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import defaultLogo from "../assets/logo.png";
import instance from "../services/apiServices/Api";
import { errorMessage } from '../helpers/Message';

const defaultTitle = "Picha Stock";

const JSONLD = () => (
  <script type="application/ld+json">
      {`
          {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "https://www.pichastock.com",
              "name": "Picha Stock",
              "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "Customer Service"
              }
          }
      `}
  </script>
);

const SEO = ({ title, description, logo }) => {
    const [homeContent, setHomeContent] = useState({});

    useEffect(() => {
        const controller = new AbortController();
        getHomepageContent();
        return () => controller.abort();
    }, []);

    const getHomepageContent = async () => {
        await instance
            .get("homepage-content")
            .then((res) => {
                setHomeContent(res.data.data.homepageContent);
            })
            .catch((error) => {
                errorMessage("Error!", error?.response?.data?.message);
            });
    };

    return (
        <Helmet>
            <title>{title ? title : defaultTitle}</title>
            <meta name="description" content={description ? description : homeContent?.google_search_description} />
            <meta name="title" content={title ? title : defaultTitle} />
            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title ? title : defaultTitle} />
            <meta property="og:description" content={description ? description : homeContent?.google_search_description} />
            <meta property="og:image" content={logo ? logo : defaultLogo} />
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title ? title : defaultTitle} />
            <meta name="twitter:description" content={description ? description : homeContent?.google_search_description} />
            <meta name="twitter:image" content={logo ? logo : defaultLogo} />
            {/* Canonical Tag */}
            <link rel="canonical" href={window.location.href} />

            <JSONLD />
        </Helmet>
    );
};

export default SEO;
