import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import pagenotfound from "../assets/pageNotFound.svg"
import SEO from '../components/SEO';

export default function PageNotFound({ redirect }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (redirect === '404') {
      navigate("/not-found")
    }
    // eslint-disable-next-line 
  }, [])
  return (
    <>
      <SEO />
      <div className="container">
        <div className="align-items-center flex-wrap main-page-outer outer-row">
          <div className="column-medium-6 column-12 ">
            <div className="text-center member-column rounded-corner">
              <h2>Page not found</h2>
              <p className="mt-3">
              </p>
              <div className="sizes-btn-outer mt-3">
                <Link to="/" className="btn primary-btn btn-large">Back to Home</Link>
              </div>
            </div>
          </div>
          <div className="column-medium-6 column-12 ">
            <img src={pagenotfound} alt="404" className='full-width' />
          </div>
        </div>
      </div>
    </>
  );
} 