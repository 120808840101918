import {
  faEye,
  faFileArrowDown,
  faHandHoldingDollar,
  faReceipt,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import instance from "../../services/apiServices/Api";
import Storage from "../../services/local_storage/localStorage";
import { Link } from "react-router-dom";
import SimpleBackdrop from "../../components/Backdrop";
import ChartStats from "../../components/ChartStats";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "../../components/Card";
import placeholderImg from "../../assets/imagePlaceholder.jpg";



const MediaTypeListing = [
  // { type: "both", name: "All" },
  { type: "image", name: "Image" },
  { type: "video", name: "Video" },
];
const TimeSpanListing = [
  { type: "week", name: "Weekly" },
  { type: "month", name: "Monthly" },
  { type: "year", name: "Yearly" },
];

function Dashboard() {
  const token = Storage.getToken();
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const [data, setData] = useState({});
  const userData = Storage.getUserData();
  const [loading, setLoading] = useState(false);
  const [mediaType, setMediaType] = useState("image");
  const [timeSpan, setTimeSpan] = useState("week");
  const [statsData, setStatsData] = useState({});

  useEffect(() => {
    if (userData.role_label === "Contributor") {
      getStatsData(timeSpan, mediaType);
    }
    getData();

    // eslint-disable-next-line
  }, [timeSpan, mediaType]);

  const ToggleDropdowns = (value) => {
    if (value === "open-filter-variation") {
      document
        .getElementById("open-filter-variation")
        .classList.toggle("open-filter-variation");
      document
        .getElementById("open-filter-variation2")
        .classList.remove("open-filter-variation");
    } else {
      document
        .getElementById("open-filter-variation2")
        .classList.toggle("open-filter-variation");
      document
        .getElementById("open-filter-variation")
        .classList.remove("open-filter-variation");
    }
  };

  const getData = () => {
    setLoading(true);
    instance
      .get(
        userData.role_label === "Contributor"
          ? "/contributor/dashboard"
          : "/user/dashboardCollection",
        header
      )
      .then((res) => {
        setData(res.data.data);
        Storage.SetPreferenceStep(res?.data?.current_step);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Err", err);
      });
  };
  const getStatsData = (timeSpan, mediaType) => {
    const data = {
      list: timeSpan,
      stat_type: "",
      media_type: mediaType,
    };
    instance.post("/contributor/chart-stats", data).then((res) => {
      setStatsData(res.data.data);
    });
  };

  return (
    <>
      {loading && <SimpleBackdrop />}
      <div className="position-relative">
        <h2 className="dashboard-title">{userData.role_label} Dashboard</h2>
        {userData.role_label === "Contributor" ? (
          <>
            <div className="d-flex row dashboard-contributed">
              <Link
                className="m-3-full-width"
                to="/contributor-dashboard/gallery"
              >
                <div className="dasboard-column-outer  das-totalDownload">
                  <h2>{data?.totalDownloads}</h2>
                  <div className="dash-card-outer d-flex">
                    <div className="dash-count">Total Downloads</div>
                    <div className="dash-icon">
                      <FontAwesomeIcon icon={faFileArrowDown} />
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                className="m-3-full-width"
                to="/contributor-dashboard/gallery"
              >
                <div className="dasboard-column-outer  das-totalLikes">
                  <h2>{data?.totalLike}</h2>
                  <div className="dash-card-outer d-flex">
                    <div className="dash-count">Total Like</div>
                    <div className="dash-icon">
                      <FontAwesomeIcon icon={faThumbsUp} />
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                className="m-3-full-width"
                to="/contributor-dashboard/orders"
              >
                <div className="dasboard-column-outer  das-totalOrder">
                  <h2>{data?.totalOrders}</h2>
                  <div className="dash-card-outer d-flex">
                    <div className="dash-count">Total Orders</div>
                    <div className="dash-icon">
                      <FontAwesomeIcon icon={faReceipt} />
                    </div>
                  </div>
                </div>
              </Link>
              <div className="m-3-full-width">
                <div className="dasboard-column-outer  das-totalView">
                  <h2>{data?.totalView}</h2>
                  <div className="dash-card-outer d-flex">
                    <div className="dash-count">Total View</div>
                    <div className="dash-icon">
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                  </div>
                </div>
              </div>
              <Link
                className="m-3-full-width"
                to="/contributor-dashboard/earnings"
              >
                <div className="dasboard-column-outer  das-totalEarning">
                  <h2>$ {data?.total_earnings}</h2>
                  <div className="dash-card-outer d-flex">
                    <div className="dash-count">Total Earnings</div>
                    <div className="dash-icon">
                      <FontAwesomeIcon icon={faHandHoldingDollar} />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className=" d-flex align-items-center justify-content-between mt-50 graph-outer">
              <div className="m-3-full-width">
                <div
                  onClick={() => ToggleDropdowns("open-filter-variation")}
                  className="field-outer"
                >
                  <label className="input-label">media</label>
                  <div id="open-filter-variation" className="search-variation">
                    <div className="inputField">
                      {
                        MediaTypeListing.find((item) => item.type === mediaType)
                          .name
                      }
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M201.4 374.6c12.5 12.5 32.8 12.5 
                                                    45.3 0l160-160c12.5-12.5 12.5-32.8 
                                                    0-45.3s-32.8-12.5-45.3 0L224 306.7 
                                                    86.6 169.4c-12.5-12.5-32.8-12.5-45.3 
                                                    0s-12.5 32.8 0 45.3l160 160z"
                        />
                      </svg>
                    </div>
                    <div className="search-variation-submenu">
                      <ul className="search-variation-list">
                        {MediaTypeListing.map((item, i) => {
                          return (
                            <li onClick={() => setMediaType(item.type)} key={i}>
                              {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-3-full-width">
                <div
                  onClick={() => ToggleDropdowns("open-filter-variation2")}
                  className="field-outer "
                >
                  <label className="input-label">Time Span</label>
                  <div id="open-filter-variation2" className="search-variation">
                    <div className="inputField">
                      {
                        TimeSpanListing.find((item) => item.type === timeSpan)
                          .name
                      }
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M201.4 374.6c12.5 12.5 32.8 12.5 
                                                    45.3 0l160-160c12.5-12.5 12.5-32.8
                                                     0-45.3s-32.8-12.5-45.3 0L224 306.7 
                                                     86.6 169.4c-12.5-12.5-32.8-12.5-45.3 
                                                     0s-12.5 32.8 0 45.3l160 160z"
                        />
                      </svg>
                    </div>
                    <div className="search-variation-submenu">
                      <ul className="search-variation-list">
                        {TimeSpanListing.map((item, i) => {
                          return (
                            <li onClick={() => setTimeSpan(item.type)} key={i}>
                              {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-3 graph-outer-dashboard">
              <ChartStats
                Lable={statsData?.profile_view?.labels}
                StatTitle={"Profile Views"}
                DataLable={"View"}
                Data={statsData?.profile_view?.values}
              />
              <ChartStats
                Lable={statsData?.commissions?.labels}
                StatTitle={"Earnings"}
                DataLable={"Earnings"}
                Data={statsData?.commissions?.values}
              />
              <ChartStats
                Lable={statsData?.orders?.labels}
                StatTitle={"Orders"}
                DataLable={"Orders"}
                Data={statsData?.orders?.values}
              />
              <ChartStats
                Lable={statsData?.downloads?.labels}
                StatTitle={`Media-${mediaType}`}
                DataLable={"Media"}
                Data={statsData?.downloads?.values}
              />
            </div>
          </>
        ) : (
          <>
            <div className="container">
              {data?.recomendedCollection?.length > 0 && (
                <div className="collection-carousel">
                  <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerclassName="container-with-dots"
                    dotListclassName=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClassName="carousel-item-padding-40-px"
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 6000,
                          min: 1500,
                        },
                        items: 4,
                        partialVisibilityGutter: 40,
                      },
                      mobile: {
                        breakpoint: {
                          max: 1499,
                          min: 1200,
                        },
                        items: 3,
                        partialVisibilityGutter: 30,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                      },
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderclassName=""
                    slidesToSlide={1}
                    swipeable
                    itemClass="carousel-item-padding-40-px"
                  >
                    {data?.seasionalCollection?.map((item, i) => {
                      return <Card item={item} key={i} />;
                    })}
                  </Carousel>
                </div>
              )}
              <div className="main-page-outer">
                <h2 className="mb-5 text-center">Recommended for you</h2>

                {data?.seasionalCollection?.length > 0 && (
                  <div className="collection-carousel">
                    <Carousel
                      additionalTransfrom={0}
                      arrows
                      autoPlaySpeed={3000}
                      centerMode={false}
                      className=""
                      containerclassName="container-with-dots"
                      dotListclassName=""
                      draggable
                      focusOnSelect={false}
                      infinite
                      itemClassName="carousel-item-padding-40-px"
                      keyBoardControl
                      minimumTouchDrag={80}
                      pauseOnHover
                      renderArrowsWhenDisabled={false}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      responsive={{
                        desktop: {
                          breakpoint: {
                            max: 6000,
                            min: 1500,
                          },
                          items: 5,
                          partialVisibilityGutter: 40,
                        },
                        mobile: {
                          breakpoint: {
                            max: 1499,
                            min: 1200,
                          },
                          items: 3,
                          partialVisibilityGutter: 30,
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464,
                          },
                          items: 2,
                          partialVisibilityGutter: 30,
                        },
                      }}
                      rewind={false}
                      rewindWithAnimation={false}
                      rtl={false}
                      shouldResetAutoplay
                      showDots={false}
                      sliderclassName=""
                      slidesToSlide={1}
                      swipeable
                      itemClass="carousel-item-padding-40-px"
                    >
                      {data?.recomendedCollection?.map((item, i) => {
                        return (
                          <div className="search-result-column" key={i}>
                            <Link to={`/downloads/${item.downloads[0].slug}`}>
                              <img
                                loading="lazy"
                                className="imgWidth"
                                src={
                                  item?.downloads[0]?.watermarked_file?.file ??
                                  placeholderImg
                                }
                                alt={item?.keyword}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = placeholderImg;
                                }}
                                />
                              <p>{item?.downloads[0]?.title}</p>
                            </Link>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
