import React from 'react';
import { Box, Button as MuiButton } from '@mui/material';

export const buttonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

export const buttonVariants = {
  PRIMARY: 'primary',
  DEFAULT: 'Default',
  SECONDARY: 'secondary',
};

const AppCustomButton = ({
  children,
  icon,
  variant = buttonVariants.PRIMARY,
  size = buttonSizes.MEDIUM,
  onClick,
  ...props
}) => {
  const styles = {
    [buttonVariants.PRIMARY]: {
      backgroundColor: '#FF5BEF',
      color: '#fff',
      border: 'none',
      '&:hover': {
        backgroundColor: '#e44edc',
      },
    },

    [buttonVariants.DEFAULT]: {
        backgroundColor: '#FF5BEF',
        color: '#fff',
        border: 'none',
        '&:hover': {
          backgroundColor: '#e44edc',
        },
      },
    [buttonVariants.SECONDARY]: {
      backgroundColor: 'transparent',
      color: '#FF5BEF',
      border: '1px solid #FF5BEF',
      '&:hover': {
        backgroundColor: 'rgba(255, 0, 128, 0.1)',
      },
    },
    [buttonSizes.LARGE]: {
      height: '50px',
      width: '100%',
      borderRadius: '25px',
    },
    [buttonSizes.MEDIUM]: {
      height: '40px',
      borderRadius: '20px',
    },
    [buttonSizes.SMALL]: {
      height: '30px',
      borderRadius: '15px',
    },
  };

  const iconWithColor = icon ? React.cloneElement(icon, { style: { color: styles[variant].color } }) : null;

  return (
    <Box sx={{ width: '100%' }}>
      <MuiButton
        startIcon={iconWithColor}
        sx={{
          ...styles[variant],
          ...styles[size],
          textTransform: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={onClick}
        {...props}
      >
        {children}
      </MuiButton>
    </Box>
  );
};

export default AppCustomButton;
