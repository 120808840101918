import React from "react";
import { useParams } from "react-router-dom";
import TittleBanner from "../../components/TitleBanner";
import WishlistDetailsComponent from "../../components/WishlistDetailsComponent";
import SEO from "../../components/SEO";

const DetailsWishListPage = () => {
    const { id } = useParams();
    console.log('id', id, 'DetailsWishListPage');
    const item = {
        title: "Wishlist Detail",
        link: "/",
        breadCrumbs_main: "Wishlists",
        mainLink: "/wishLists",
        breadCrumbs_submain: "Wishlist Detail",
    };

    return (
        <> 
            <SEO />
            <TittleBanner item={item} />
            <WishlistDetailsComponent id={id} />
        </>
    );
};

export default DetailsWishListPage;
